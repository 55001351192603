import { JSON_CONTENT_TYPE_HEADER } from './headers.js'
import { useWFetch } from './useWFetch.js'
import { wfetch } from './wfetch.js'

export const NAMED_REQUESTS = {
  currentUser: Symbol('currentUser'),
  getActualTemplate: Symbol('getActualTemplate'),
  getActualTopNavigation: Symbol('getActualTopNavigation'),
  getBannersInfo: Symbol('getBannersInfo'),
  myNotifications: Symbol('myNotifications'),
  splits: Symbol('splits'),
  getMergedHomeDashboard: Symbol('getMergedHomeDashboard'),
  getMyDashboards: Symbol('getMyDashboards'),
  myWorkWidgetData: Symbol('myWorkWidgetData'),
  myWorkFilteredAssignments: Symbol('myWorkFilteredAssignments'),
}

export function namedRequest(name) {
  const args = namedRequests[name]
  return wfetch.apply(null, args)
}

export function useNamedRequest(name) {
  const args = namedRequests[name]
  return useWFetch.apply(null, args)
}

Object.entries(NAMED_REQUESTS).forEach(([key, value]) => {
  namedRequest[key] = () => namedRequest(value)
  useNamedRequest[key] = value
})

const {
  splits,
  currentUser,
  getActualTemplate,
  getActualTopNavigation,
  myNotifications,
  getBannersInfo,
  getMyDashboards,
  getMergedHomeDashboard,
  myWorkWidgetData,
  myWorkFilteredAssignments,
} = window?.qsBootstrapCalls ?? {}

const namedRequests = {
  [NAMED_REQUESTS.splits]: [
    '/internal/_splits',
    {
      method: 'POST',
      headers: JSON_CONTENT_TYPE_HEADER,
      body: '{"serviceID":"quicksilver"}',
    },
    {
      initialRequest: splits,
      timeToExpiration: 1e3 * 60 * 60 * 24,
    },
  ],
  [NAMED_REQUESTS.currentUser]: [
    '/attask/api-internal/USER/currentUser?fields=*,accessLevel:*,customer:*,customer:queryLimit,customer:products:*',
    {},
    {
      initialRequest: currentUser,
      timeToExpiration: 1e3 * 60 * 60 * 24,
    },
  ],
  [NAMED_REQUESTS.getActualTemplate]: [
    '/attask/api-internal/UITMPL?action=getActualTemplate&method=PUT',
    {},
    {
      initialRequest: getActualTemplate,
      timeToExpiration: 1e3 * 60 * 60 * 2,
    },
  ],
  [NAMED_REQUESTS.getActualTopNavigation]: [
    '/attask/api-internal/UITMPL?action=getActualTopNavigation&method=PUT',
    {},
    {
      initialRequest: getActualTopNavigation,
      timeToExpiration: 1e3 * 60 * 60 * 2,
    },
  ],
  [NAMED_REQUESTS.getBannersInfo]: [
    '/internal/getBannersInfo',
    {},
    {
      initialRequest: getBannersInfo,
      timeToExpiration: 1e3 * 60 * 60 * 24,
    },
  ],
  [NAMED_REQUESTS.myNotifications]: [
    '/internal/notification/myNotifications?limit=80&includeAll=false',
    {},
    {
      initialRequest: myNotifications,
      timeToExpiration: 1e3 * 60 * 10,
    },
  ],
  [NAMED_REQUESTS.getMyDashboards]: [
    '/dashboard-service/graphql',
    {
      method: 'POST',
      headers: JSON_CONTENT_TYPE_HEADER,
      body: '{"operationName":"GetMyDashboards","variables":{}},"query":"fragment WidgetData on Widget {\\n  id\\n  x\\n  y\\n  h\\n  w\\n contentMetadata {\\n    id\\n    type\\n    __typename\\n  }\\n  __typename\\n}\\n\\nfragment DashboardData on Dashboard {\\n  id\\n  type\\n  widgets {\\n    ...WidgetData\\n    __typename\\n  }\\n  __typename\\n}\\n\\nquery GetMyDashboards {\\n  getMyDashboards(filter: {type: Home}, dashboardType: Home) {\\n    ...DashboardData\\n    __typename\\n  }\\n}\\n"}',
    },
    {
      initialRequest: getMyDashboards,
      preventAutoRedirect: true,
      timeToExpiration: 1e3 * 30,
    },
  ],
  [NAMED_REQUESTS.getMergedHomeDashboard]: [
    '/dashboard-service/graphql',
    {
      method: 'POST',
      headers: JSON_CONTENT_TYPE_HEADER,
      body: '{"operationName":"GetMergedHomeDashboard","variables":{},"query":"fragment WidgetData on Widget {\\n  id\\n  x\\n  y\\n  h\\n  w\\n isLayoutTemplateWidget\\n  contentMetadata {\\n    id\\n    type\\n    __typename\\n  }\\n  __typename\\n}\\n\\nfragment DashboardData on Dashboard {\\n  id\\n  type\\n  widgets {\\n    ...WidgetData\\n    __typename\\n  }\\n  __typename\\n}\\n\\nquery GetMergedHomeDashboard {\\n  dashboard: getMergedHomeDashboard {\\n    ...DashboardData\\n    __typename\\n  }\\n}\\n"}',
    },
    {
      initialRequest: getMergedHomeDashboard,
      preventAutoRedirect: true,
      timeToExpiration: 1e3 * 30,
    },
  ],
  [NAMED_REQUESTS.myWorkWidgetData]: [
    '/internal/home/myWorkWidgetData',
    {},
    {
      initialRequest: myWorkWidgetData,
      timeToExpiration: 1e3 * 30,
    },
  ],
  [NAMED_REQUESTS.myWorkFilteredAssignments]: [
    '/ui-data/myWork/filteredAssignments',
    {},
    {
      initialRequest: myWorkFilteredAssignments,
      timeToExpiration: 1e3 * 30,
    },
  ],
}
