import { getStorageUtil } from '@workfront/storage'

import { MaxMemoryTTLRequestStore } from './MaxMemoryTTLRequestStore.js'
import { simpleHash } from './simpleHash.js'

const sessionStorageStore = getStorageUtil({
  backend: sessionStorage,
  storagePrefix: 'wfetch:',
})

export class WfetchCache {
  static memoryStore = new MaxMemoryTTLRequestStore()
  static sessionStorageStore = sessionStorageStore

  constructor(
    url,
    fetchOptions,
    {
      initialRequest,
      refetching,
      timeToExpiration,
      isDataSensitive = true,
    } = {},
  ) {
    this.caching = timeToExpiration !== undefined
    this.refetching = refetching

    if (this.caching) {
      this.fetch = () => fetch(url, fetchOptions)
      this.makeRequest = () => initialRequest || this.fetch()
      this.timeToExpiration = timeToExpiration
      this.isDataSensitive = isDataSensitive

      let cacheKey
      try {
        // eslint-disable-next-line no-unused-vars
        const { signal, ...nonUseWFetchOptions } = fetchOptions
        const optionsToIncludeInHash = structuredClone(nonUseWFetchOptions)
        delete optionsToIncludeInHash.headers['x-request-id']
        const fetchOptionsHash = simpleHash(
          JSON.stringify(optionsToIncludeInHash),
        )
        const workfrontOptionsHash = simpleHash(
          JSON.stringify({
            timeToExpiration,
            isDataSensitive,
            initialRequest: initialRequest ? 1 : 0,
          }),
        )
        cacheKey = `${url}-${fetchOptionsHash}-${workfrontOptionsHash}`
      } catch (e) {
        cacheKey = url
      }

      this.cacheKey = cacheKey
    }
  }

  clearCachedResponse() {
    if (this.caching) {
      this.makeRequest = this.fetch
      WfetchCache.memoryStore.delete(this.cacheKey)
    }
  }

  cleanUpTemporaryCacheOfSessionStorageRequest() {
    WfetchCache.memoryStore.delete(this.cacheKey)
  }

  getCachedData() {
    if (this.caching && !this.isDataSensitive) {
      const cachedData = WfetchCache.sessionStorageStore.get(this.cacheKey)

      if (cachedData != null && WfetchCache.memoryStore.has(this.cacheKey)) {
        this.cleanUpTemporaryCacheOfSessionStorageRequest()
      }

      return cachedData
    }

    return null
  }

  cacheResponseData(responseData) {
    if (this.caching && !this.isDataSensitive) {
      WfetchCache.sessionStorageStore.set(
        this.cacheKey,
        responseData,
        this.timeToExpiration,
      )
    }
  }

  async checkIfRequestFailed(requestPromise) {
    try {
      const response = await requestPromise
      return !response.ok
    } catch (error) {
      return true
    }
  }

  getCachedResponse() {
    if (this.caching && !this.refetching) {
      if (!WfetchCache.memoryStore.has(this.cacheKey)) {
        const request = this.makeRequest()

        WfetchCache.memoryStore.set(
          this.cacheKey,
          request,
          this.timeToExpiration,
        )

        this.checkIfRequestFailed(request).then((isFailed) => {
          if (isFailed) {
            this.clearCachedResponse()
          }
        })
      }

      return WfetchCache.memoryStore
        .get(this.cacheKey)
        .then(cloneCachedResponse)
    }
  }
}

function cloneCachedResponse(response) {
  return response.clone()
}
