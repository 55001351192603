// we borrowed this from jQuery (I believe) years ago
// this has been copied and pasted into 2-3 Workfront api implementations so far
var r20 = /%20/g,
  rbracket = /\[\]$/

function buildParams(prefix, obj, traditional, add) {
  if (Array.isArray(obj)) {
    // Serialize array item.
    for (var i = 0; i < obj.length; i++) {
      var v = obj[i]

      if (traditional || rbracket.test(prefix)) {
        // Treat each array item as a scalar.
        // eslint-disable-next-line
        try {
          add(prefix, v)
        } catch (e) {
          throw e
        }
      } else {
        // Item is non-scalar (array or object), encode its numeric index.
        buildParams(
          prefix + '[' + (typeof v === 'object' ? i : '') + ']',
          v,
          traditional,
          add,
        )
      }
    }
  } else if (!traditional && typeof obj === 'object') {
    // Serialize object item.
    for (const name in obj) {
      buildParams(prefix + '[' + name + ']', obj[name], traditional, add)
    }
  } else {
    // Serialize scalar item.
    add(prefix, obj)
  }
}

function param(a, traditional) {
  const s = []
  const add = (key, value) => {
    // If value is a function, invoke it and return its value
    value = typeof value === 'function' ? value() : value == null ? '' : value

    s[s.length] = encodeURIComponent(key) + '=' + encodeURIComponent(value)
  }

  // If an array was passed in, assume that it is an array of form elements.
  if (a instanceof Array) {
    // Serialize the form elements
    for (const name in a) {
      const value = a[name]
      add(name, value)
    }
  } else {
    for (const prefix in a) {
      buildParams(prefix, a[prefix], traditional, add)
    }
  }

  // Return the resulting serialization
  return s.join('&').replace(r20, '+')
}

const bodyFieldsToStringify = ['fields', 'filters', 'updates']

/**
 * Encodes an object as form data in a way the `/attask/api-internal` endpoint can process.
 * @param {Object} body  JSON object to encode
 * @returns {string}
 */
export function encodeAsFormData(body = {}) {
  let encodedBody = { ...body }

  bodyFieldsToStringify.forEach((key) => {
    if (encodedBody[key] && typeof encodedBody[key] !== 'string') {
      encodedBody[key] = JSON.stringify(encodedBody[key])
    }
  })

  encodedBody = param(encodedBody)

  return encodedBody
}
